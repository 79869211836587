@font-face {
  font-family: Lora;
  src: url("./assets/fonts/Lora-Regular.ttf");
}

@font-face {
  font-family: Lora;
  src: url("./assets/fonts/Lora-Italic.ttf");
  font-style: italic;
}

@font-face {
  font-family: Lora;
  src: url("./assets/fonts/Lora-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Lora;
  src: url("./assets/fonts/Lora-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Lora;
  src: url("./assets/fonts/Lora-SemiBold.ttf");
  font-weight: 600;
}

body,
html {
  font-family: Lora, "Open Sans", sans-serif !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
}

body {
  background-color: #1B0000;
  overflow-x: hidden;
}

h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
}

h2 {
  text-transform: uppercase;
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 36px;
  color: #333;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

h4 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}

h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}

p {
  font-size: 15px;
}

p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}

a {
  color: #608dfd;
  font-weight: 400;
}

a:hover,
a:focus {
  text-decoration: none;
  color: #608dfd;
}

ul,
ol {
  list-style: none;
}

ul,
ol {
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}


.container {
  min-height: 100vh !important;
  max-width: 1336px;
  margin: 0 auto !important;
  justify-content: space-between;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.left-box {
  height: inherit;
  max-width: 987px;
  margin-right: 0;
  margin-left: 0;
  padding-right: 25px;
  display: flex;
  justify-items: center;
  justify-content: center;
}

@media screen and (max-width: 1400px) {
  .left-box {
    max-width: 935px;
  }
}

.left .top-box {
  padding: 0;
  background-image: url(./assets/img/bg2.png), url(./assets/img/bg1.png);
  background-position: 49% 50%, center center;
  background-repeat: no-repeat, no-repeat;
  -webkit-background-size: 135%, 100% 100%;
  -moz-background-size: 135%, 100% 100%;
  background-size: 135%, 100% 100%;
  -o-background-size: 135%, 100% 100%;

  display: inline-flex;
  align-items: center;
  position: relative;
}

.left .top-box::before {
  background: url(./assets/img/bg1.png) center center no-repeat;
}

.left .top-btn-box {
  position: absolute;
  top: 0;
  left: 0;
}

.left .bottom-box {
  display: flex;
  justify-content: center;
  height: 300px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.left .bottom-box span.text {
  font-size: 16px;
  font-weight: 600;
}

.left .ong-dia-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 2;
}

.left .btn-vai-normal {
  height: 100%;
  width: 230px;
  border: none;
  background: url(./assets/img/btn_vai_nomarl.png) no-repeat center center;
  background-size: contain;
  padding: 2px 0;
  cursor: pointer;
}

.left .btn-vai-click {
  height: 100%;
  width: 230px;
  border: none;
  background: url(./assets/img/btn_vai_click.png) no-repeat center center;
  background-size: contain;
  padding: 2px 8px;
  cursor: pointer;
}

.left .btn-vai-hide {
  height: 100%;
  width: 230px;
  border: none;
  background: url(./assets/img/btn_vai_hide.png) no-repeat center center;
  background-size: contain;
  padding: 2px 8px;
  cursor: pointer;
}

.left .vai-icon {
  height: 250px;
  width: 100%;
  border: none;
  background: url(./assets/img/vai_icon.png) no-repeat center center;
  background-size: contain;
  transition: visibility 0s, opacity 1s linear;
}

.left span.text {
  color: #DE9951;
}

.btn-vai-box {
  width: 100%;
  height: 100px;
  text-align: center;
}

.right {
  position: relative;
  height: 100vh;
  padding-bottom: 30px;
  overflow-y: hidden;
  max-height: 100vh;
}

.right .header {
  position: relative;
  padding-top: 50px;
  padding-bottom: 15px;
  font-size: 28px;
  color: #DE9951;
}

.right .chat-content {
  height: calc(100vh - 330px);
  overflow: hidden;
  scroll-snap-type: y mandatory;
  padding-right: 9px;
  scrollbar-color: #472A1B transparent;
  scrollbar-width: thin;
}

.right .chat-content:hover {
  overflow-y: scroll;
}

.right .chat-box-container {
  position: relative;
}

.right .chat-box-container::after {
  position: absolute;
  content: " ";
  top: 0;
  height: 100px;
  width: 100%;
  background: rgb(27, 0, 0);
  background: linear-gradient(180deg, rgba(27, 0, 0, 0.8744091386554622) 18%, rgba(40, 13, 8, 0) 75%);
}

.right .chat-content::-webkit-scrollbar {
  -webkit-box-shadow: inset 0 0 6px transparent;
  border-radius: 4px;
  background-color: transparent;
}

.right .chat-content::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.right .chat-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #472A1B;
}

.right .chat-content section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.right span.text {
  color: #DE9951;
}

.right .msg {
  color: #DE9951;
  font-size: 12px;
  margin-bottom: 0;
}

.opacity-50 {
  opacity: 0.3;
}

.right .timestamp {
  color: rgba(170, 114, 63, 0.6);
  font-size: 12px;
}

.right .chat-input-box {
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.right .chat-input {
  width: 100%;
  background-color: #451D10;
  color: #DE9951;
  resize: none;
  border-radius: 2px;
  padding: 18px 45px 18px 16px;
  font-size: 12px;
  outline: none;
  border-color: transparent;
  scrollbar-width: none;
}

.right .chat-input::placeholder {
  color: rgba(222, 153, 81, 0.4);
}

.right .chat-input::-webkit-scrollbar {
  display: none;
}

.right .msg-container {
  background-color: #280D08;
  border-radius: 4px;
  padding: 12px 16px 12px 16px;
  transition: background-color 0.4s ease;
  width: fit-content;
}

.right .msg-container:first-child {
  margin-top: 50px;
}

.right .chat-note {
  margin-top: 3px;
  margin-bottom: 30px;
}

.right .text-note {
  font-size: 10px;
  color: rgba(222, 153, 81, 0.6);
}

.right .image-license {
  font-size: 12px;
  color: rgba(222, 153, 81, 0.3);
}

.right .text-note-warning {
  font-size: 10px;
  color: #AD3606;
}

.row:not(.not-effect) {
  margin-right: 0;
  margin-left: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.right-box {
  padding-right: 0;
  padding-left: 20px;
  width: 287px;
  margin-left: 0;
  margin-right: 0;
}

.right .image-license-box {
  position: absolute;
  bottom: 30px;
}

.license-box-opacity {

}

.right .send-icon {
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.followButtonClassName {
  display: none;
}

.msg-highlight {
  background-color: #451D10 !important;
}

.sound-btn {
  width: 44px;
  height: 44px;
  background-color: rgba(170, 114, 63, 0.3);
  border-radius: 2px;
  margin-top: 50px;
}

.bookmark-btn {
  height: 44px;
  background-color: rgba(170, 114, 63, 0.3);
  border-radius: 2px;
  margin-right: 8px;
  color: #DE9951;
  margin-top: 50px;
  margin-left: 50px;
}

.bookmark-btn:hover {
  color: #DE995190;
}

.share-fb-text {
  font-size: 16px;
  font-weight: 700;
  color: #DE9951;
}

.right .tablet-ads {
  display: none;
}

.bookmark-text {
  padding-left: 10px;
  font-weight: bold;
}

.time-countdown {
  color: #F2CC81;
}

.btn {
  border: none !important;
  box-shadow: none !important;
}

#repro {
  width: 100%;
  height: 100%;
  overflow: inherit;
  position: absolute;
  border: none;
  opacity: 1;
}

#canvasMiddle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
#canvasLeft,
#canvasRight {
  position: absolute;
  bottom: 50vh;
  width: 100vh;
  height: 100vw;
  transform-origin: bottom center;
}
#canvasLeft {
  left: -50vh;
  transform: rotate(90deg);
}
#canvasRight {
  right: -50vh;
  transform: rotate(-90deg);
}

@media screen and (max-width: 1200px) {
  .left .top-box {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .left .bottom-box {
    height: auto;
  }

  .left .top-btn-box {
    top: -30px;
    left: -30px;
  }

  @media screen and (min-width: 600px) {
    .right {
      display: flex;
    }

    .right .chat-container,
    .right .tablet-ads {
      display: block;
      flex: 1;
    }

    .desktop-ads {
      display: none;
    }
  }
}

@media screen and (max-width: 990px) {
  .container {
    margin: 0 !important;
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .right-box {
    width: 100% !important;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .right .header {
    text-align: center;
  }

  .left-box {
    padding-right: 0;
    padding-left: 0;
  }

  .row.not-effect {
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

/* mobile screen */
@media screen and (max-width: 500px) {
  .container {
    justify-content: center !important;
  }

  body {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
  }

  body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  #canvasMiddle {
    top: -15px;
    left: -15px;
    right: -15px;
    width: 100vw;
    height: 100vh;
  }

  #canvasLeft {
    left: -52vh;
  }

  #canvasRight {
    right: -52vh;
  }

  .chat-input-box-mobile {
    position: fixed !important;
    bottom: 0;
    width: 92vw !important;
    background-color: #1B0000;
  }

  .chat-content-when-fixed-comments {
    height: calc(100vh - 200px) !important;
  }

  .chat-input-box {
    margin-top: 8px !important;
  }

  .hidden {
    display: none;
  }

  .right .chat-note {
    margin-top: 0 !important;
    margin-bottom: 0!important;
    padding-top: 8px;
    padding-bottom: 16px;
  }

  .license-box-opacity {
    opacity: 0;
  }
}